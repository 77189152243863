
// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

// Variables
// @import "variables";

// Bootstrap
// @import '~bootstrap/scss/bootstrap';
@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "~datatables.net-buttons-bs4/css/buttons.bootstrap4.css";
@import '~datatables.net-select-bs4/css/select.bootstrap4.css';
@import '~datatables.net-searchpanes-bs4/css/searchPanes.bootstrap4.css';

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
  
}

